/* ToDo: Add Variables! */
/* //Blue: #19508E, Green: #92BD55 */



.App {
  display: flex;
  height: 100vh;
  display: flex;
  align-items: top;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #92BD55 !important;
  border-color: #92BD55 !important;
  color: #FFFFFF !important;
}


.anticon {
  vertical-align: 2px !important;
}

.ant-btn-primary {
  background-color: #92BD55 !important;
  color: #FFFFFF !important;
  border-color: #92BD55 !important;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #19508E;
}

.ant-steps-finish-icon {
  color: #fff!important;
}
.lb-sm {
  font-size: 12px;
}

.lb-md {
  font-size: 16px;
}

.lb-lg {
  font-size: 20px;
}



.btn.btn-success {
  background-color: #8dc63f;
  border-color: #8dc63f;
  border-radius: 0px;
  min-width: 150px;
}

.btn.btn-primary {
  background-color: #003268;
  border-color: #003268;
  border-radius: 0px;
  min-width: 150px;
}

.btn.btn-secondary {
  border-radius: 0;
}

.btn.btn-link {
  font-size: 12px;
  color: #003268;
}

.form-group label {
  font-weight: 500;
}

@media (max-width: 480px) {
  .ant-steps-horizontal.ant-steps-label-horizontal {
    display: flex!important;
  }
}

body {
  background: #fff!important
}